<template>
  <div>
    <!--Hero-->
    <section
      class="w-full lg:px-10 lg:py-40 p-10 text-center text-white flex flex-col items-center relative bg-center bg-cover bg-no-repeat coverImage">
      <div class="relative z-30 flex flex-col items-center">
        <h1 class="text-7xl font-bold text-white">
          403
        </h1>
        <h2 class="text-5xl text-white">
          This action is unauthorized
        </h2>
        <router-link
          class="mt-16 bg-indigo-gradiant rounded-full text-white text-xl font-bold px-16 py-3 "
          to="/">Go Home
        </router-link>
      </div>
      <div class="absolute top-0 right-0 left-0 bottom-0 z-0">
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "NotFound",
  };
</script>
